@font-face {
  font-family: 'iran-sans-bold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/IRANSansWeb_Bold.eot');
  src: url('./fonts/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/IRANSansWeb_Bold.woff') format('woff'),
       url('./fonts/IRANSansWeb_Bold.woff2') format('woff2'),
       url('./fonts/IRANSansWeb_Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'besm';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/besm.eot');
  src: url('./fonts/besm.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/besm.woff') format('woff'),
       url('./fonts/besm.woff2') format('woff2'),
       url('./fonts/besm.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe-UI-Symbol';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Segoe UI Symbol.eot');
  src: url('./fonts/Segoe UI Symbol.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/Segoe UI Symbol.woff') format('woff'),
       url('./fonts/Segoe UI Symbol.woff2') format('woff2'),
       url('./fonts/Segoe UI Symbol.ttf') format('truetype');
}

@font-face {
  font-family: 'numbers';
  font-style: normal;
  font-weight: 400;
	src: url('./fonts/PlayfairDisplay-Bold.eot');
	src: url('./fonts/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/PlayfairDisplay-Bold.woff') format('woff'),
       url('./fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
       url('./fonts/PlayfairDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'iran-sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/IRANSansWeb.eot');
  src: url('./fonts/IRANSansWeb.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/IRANSansWeb.woff') format('woff'),
       url('./fonts/IRANSansWeb.woff2') format('woff2'),
       url('./fonts/IRANSansWeb.ttf') format('truetype');
}


html {
    height: 100%;
}

body {
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: -moz-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -webkit-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -ms-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.client-logos
{
  background-color: white;
  padding-left: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
  new Style
*/



/*------------------------
[TABLE OF CONTENTS]
    
1. GLOBAL STYLES
2. NAVBAR
3. HERO
4. TABS
5. TESTIMONIALS
6. IMAGE GALLERY
7. PRICING
8. CALL TO ACTION
9. FOOTER
10. Caro

------------------------*/


/* GLOBAL
----------------------*/

body {
    font-family: 'Rubik', sans-serif;
    position: relative;
}

a {
    color: #e38cb7;
}

a:hover,
a:focus {
    color: #d6619c;
}

h1 {
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 45px;
    font-weight: 300;
    color: #633991;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}

h3 {
    color: #633991;
    font-size: 33px;
    font-weight: 500;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    color: #633991;
}

h5 {
    font-size: 28px;
    font-weight: 300;
    color: #633991;
    margin-bottom: 0.7rem;
}

p {
    color: #e38cb7;
}

p.lead {
    color: #e38cb7;
    margin-bottom: 2rem;
}

.text-primary {
    color: #e38cb7 !important;
}

.light-font {
    font-weight: 300;
}

.btn {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0.375rem 1.35rem;
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    color: #d6619c;
}

.btn-info {
    border-color: #A7207E;
    background: #A7207E;
    color: #FFF;
}

.btn-info:hover {
    border-color: #482879;
    background: #482879;
}

.btn-light {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.26);
    font-size: 14px;
    font-weight: 500;
    color: #633991;
    margin: 0.5rem;
    padding: 0.7rem 1.6rem;
    line-height: 1.8;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.8rem 1rem;
    font-size: 15px;
}

.light-bg {
    background-color: #faf6fb;
}

.section {
    padding: 80px 0;
}

.section-title {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title small {
    color: #998a9b;
}

@media (max-width:767px) {
    .postTopic
    {
        width: 90% !important;
    }

    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 30px;
    }
}


/* NAVBAR
----------------------*/
.profilePills a
{
    width: 40%;
}

.nav-menu {
    transition: all 0.3s ease;
}

.navbar {
	padding-top: 0;
}

.navHide {
    top: -100px;
}

.navbar-collapse
{
    text-align: center;
}

.nav-menu.is-scrolling,
.nav-menu.menu-is-open {
    background-color: rgb(74, 13, 143);
    background: -moz-linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
    background: -webkit-linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
    background: linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
    -webkit-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
}

.nav-menu.is-scrolling {
    padding: 0;
}

.navbar-nav .nav-link {
    position: relative;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 14px;
    }
    .navbar-nav>.nav-item>.nav-link.active:after {
        content: "";
        border-bottom: 2px solid #cd99d4;
        left: 1rem;
        right: 1rem;
        bottom: 5px;
        height: 1px;
        position: absolute;
    }
}

@media (max-width:991px) {
    .navbar-nav.is-scrolling {
        padding-bottom: 1rem;
    }
    .navbar-nav .nav-item {
        text-align: center;
    }
}


/* HERO
----------------------*/

header {
    padding: 100px 0 0;
    text-align: center;
    color: #FFF;
}

.bg-gradient {
    height: 100%;
    background-image: -moz-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -webkit-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -ms-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.tagline {
    font-size: 23px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 800px;
    margin: 0 auto;
}

.img-holder {
    height: 0;
    padding-bottom: 33%;
    overflow: hidden;
}

@media (max-width:1200px) {
    .img-holder {
        padding-bottom: 50%;
    }
}

@media (max-width:767px) {
    .tagline {
        font-size: 17px;
    }
    .img-holder {
        padding-bottom: 100%;
    }
}


/* FEATURES
----------------------*/

.gradient-fill:before {
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card.features {
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

@media (max-width:991px) {
    .card.features {
        margin-bottom: 2rem;
    }
    [class^="col-"]:last-child .card.features {
        margin-bottom: 0;
    }
}

.card.features:before {
    content: "";
    position: absolute;
    width: 3px;
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    top: 0;
    bottom: 0;
    left: 0;
}

.card-text {
    font-size: 14px;
}

.card.features:hover {
    transform: translateY(-3px);
    -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
}

.box-icon {
    box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.14);
    padding: 10px;
    width: 70px;
    border-radius: 3px;
    margin-bottom: 1.5rem;
    background-color: #FFF;
}

.circle-icon {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-color: #FFF;
    color: #f5378e;
    font-size: 48px;
    text-align: center;
    line-height: 80px;
    font-weight: 300;
    transition: all 0.3s ease;
}

@media (max-width:992px) {
    .circle-icon {
        width: 70px;
        height: 70px;
        font-size: 28px;
        line-height: 50px;
    }
}

.ui-steps li:hover .circle-icon {
    background-image: -moz-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: -webkit-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: -ms-linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    background-image: linear-gradient( 122deg, #e6388e 0%, #fb378e 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
    color: #FFF;
}

.ui-steps li {
    padding: 15px 0;
}
/* 
.ui-steps li:not(:last-child) {
    border-bottom: 1px solid #f8e3f0;
} */

.perspective-phone {
    position: relative;
    z-index: -1;
}

@media (min-width:992px) {
    .perspective-phone {
        margin-top: -150px;
    }
}


/*  TABS
----------------------*/

.tab-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #FFF;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    padding: 0.8rem !important;
}

@media (max-width:992px) {
    .tab-content {
        padding: 1.5rem;
    }
}

.tab-content p {
    line-height: 1.8;
}

.tab-content h2 {
    margin-bottom: 0.5rem;
}

.nav-tabs {
    
    width: 100%;
    
}

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    padding: 1rem 1rem;
    border-color: #faf6fb #faf6fb #FFF;
    font-size: 19px;
    color: #b5a4c8;
    background: #f5eff7;
}

.nav-tabs .nav-link.active {
    /* background: purple; */
    /* border-top-width: 3px; */
    /* border-color: #ce75b4 #faf6fb #FFF; */
    /* color: #633991; */
}


/*  TESTIMONIALS
----------------------*/

.owl-carousel .owl-item img.client-img {
    width: 110px;
    margin: 30px auto;
    border-radius: 50%;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}

.testimonials-single {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
}

.blockquote {
    color: #7a767a;
    font-weight: 300;
}

.owl-next.disabled,
.owl-prev.disabled {
    opacity: 0.5;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 60px;
}

.owl-theme .owl-dots .owl-dot span {
    background: #e7d9eb;
    width: 35px;
    height: 8px;
    border-radius: 10px;
    transition: all 0.3s ease-in;
}

.owl-theme .owl-dots .owl-dot:hover span {
    background: #ff487e;
}

.owl-theme .owl-dots .owl-dot.active span {
    background: #ff487e;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}


/*  IMAGE GALLERY
----------------------*/

.img-gallery .owl-item {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    transform: scale(0.8);
    transition: all 0.3s ease-in;
}

.img-gallery .owl-item.center {
    transform: scale(1);
}


/*  PRICING
----------------------*/

@media (max-width:992px) {
    .card-deck {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .card-deck .card {
        margin-bottom: 15px;
    }
}

.card.pricing {
    border: 1px solid #f1eef1;
}

.card.pricing.popular {
    border-top-width: 3px;
    border-color: #ce75b4 #faf6fb #FFF;
    box-shadow: 0px 12px 59px 0px rgba(36, 7, 31, 0.11);
    color: #633991;
}

.card.pricing .card-head {
    text-align: center;
    padding: 40px 0 20px;
}

.card.pricing .card-head .price {
    display: block;
    font-size: 45px;
    font-weight: 300;
    color: #633991;
}

.card.pricing .card-head .price sub {
    bottom: 0;
    font-size: 55%;
}

.card.pricing .list-group-item {
    border: 0;
    text-align: center;
    color: #959094;
    padding: 1.05rem 1.25rem;
}

.card.pricing .list-group-item del {
    color: #d9d3d8;
}

.card.pricing .card-body {
    padding: 1.75rem;
}


/*  CALL TO ACTION
----------------------*/

.call-to-action {
    text-align: center;
    color: #FFF;
    margin: 3rem 0;
}

.call-to-action .box-icon {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transform: scale(0.85);
    margin-bottom: 2.5rem;
}

.call-to-action h2 {
    color: #FFF;
}

.call-to-action .tagline {
    font-size: 16px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 650px;
    margin: 0 auto;
}

.btn-light img {
    margin-right: 0.4rem;
    vertical-align: text-bottom;
}


/*  FOOTER
----------------------*/

/* sticky footer */

#root
{
    height: 100% !important;
}

#top
{
    min-height: 100% !important;
    height: auto !important;
    height: 100%;
    margin: 0 auto -46px;
}

.pagefoot
{
    height: 46px;
    padding: 20px;
}

/* end sticky footer */

.mt-1
{
    width: 25%;
    padding: 10px;
}

.social-icons {
    text-align: right;
}

.social-icons a {
    background-color: #FFF;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    margin: 0 0.3rem;
    border-radius: 5px;
    color: #f4c9e2;
    transition: all 0.3s ease;
}

.social-icons a:hover {
    text-decoration: none;
    color: #e38cb7;
}

.single-download {
	display: inline-block;
	margin: 0 15px;
	padding: 18px 25px;
	width: 25%;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

@media (max-width:991px) {
    .social-icons {
        text-align: center;
        margin-top: 2rem;
    }
}

/*  CAROUSEL
----------------------*/

.slick-list img {
    width: 100% !important;
    height: 100% !important;
    max-height: 500px;
    width: auto;
}


/*
test
*/

body
{
	font-family: iran-sans, Segoe-UI-Symbol;
}

#abuse
{
	position: absolute;
	top: 22px;
	left: 15px;
	color: #959094;
}

#abuse:hover {
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5);
}

#MOwnerPic
{
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	-o-transform: scale(0.9);
	transform: scale(0.9);
	position: relative;
	float: right;
	top: 5px;
	right: 10px;
	border-radius: 50%;
}

.MOwnerInfo
{
	float: right;
	margin-top: 10px;
	margin-right: 20px;
}

.post-card-header a:hover
{
	text-decoration: none;
}

.prevButt, .prevButt:active, .prevButt:focus
{
	position: absolute;
	top: 50%;
	left: 0;
	color: #C5C6C5;
}

.nextButt, .nextButt:active, .nextButt:focus
{
	position: absolute;
	top: 50%;
	right: 0;
	color: #C5C6C5;
}

#MOwnerName
{
	margin-bottom: 0.5rem;
	font: 14px iran-sans-bold;
	text-align: right;
}

#MTime
{
	font: 12px iran-sans;
	text-align: right;
}

.mySlides
{
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}

#MOwnerlike
{
	display: inline-block;
}

#MOwnerComment
{
	display: inline-block;
	margin-right: 10px;
}

#likeIcon
{
	margin-right: 10px;
	display: inline-block;
	height: 40px;
	width: 40px;
	color: #4c494b;
	text-align: center;
	vertical-align: middle;
	line-height: 40px;  
}

.leftIcons
{
	margin-right: 10px;
	display: inline-block;
	height: 40px;
	width: 40px;
	color: #4c494b;
	text-align: center;
	vertical-align: middle;
	line-height: 40px;
}

.leftIcons img
{
	-webkit-transform: scale(0.6);
	-moz-transform: scale(0.6);
	-ms-transform: scale(0.6);
	-o-transform: scale(0.6);
	transform: scale(0.6);
}

.post-text
{
	white-space: pre-line;
}

.post-card-header
{
	height: 70px;
    border-bottom: none;
}

.footer-the-text
{
	margin: 0 auto;
}

.pimg
{
	height: 100px;
	width: 100px;
	position: absolute;
	border-radius: 50%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: -50px;
	border: 3px solid white;
	box-shadow: 0 5px 5px gray;
}

.card-header
{
	border-radius: 25px 25px 0 0 !important ;
	padding: 0;
/*	background-size: cover;
	background-color: transparent;
	border-color: transparent;
	background-position: center; */
}

.btnRight
{
	height: 50px;
	border-radius: 0 0 10px 0;
}

.btnLeft
{
	height: 50px;
	border-radius: 0 0 0 10px;
}

.card-header img
{
	border-radius: 15px 15px 0 0;
}

.card-body
{
	border-radius: 0 0 15px 15px;
	padding: 0;
}

.card
{
	margin-top: 0;
	margin-bottom: 30px;
	width: 500px;
	font: 13px iran-sans;
	border-radius: 15px;
	border-color: transparent;
}

.card-footer
{
	font: 14px iran-sans;
	border-radius: 0 0 25px 25px !important;
	height: 60px;
	color: #959094;
    border-top: none;
}

.card-footer-right
{
	display: inline-block;
    float: right;
}

.card-footer-left
{
	float: left;
}

/* font class */
.bold-sans-bg
{
	font: 24px iran-sans-bold;
}

.bold-sans
{
	font: 13px iran-sans-bold;
}

.sans
{
	font: 16px iran-sans;
}

.numbers
{
	font: 23px iran-sans-bold;
}

.username
{
	font-family: iran-sans-bold, Calibri;
	padding-top: 10px;
}

.col
{
	padding: 0;
}

.col h5
{
	line-height: 60%;
}

.btn
{
	font: 15px iran-sans-bold;
}

.medals
{
	text-align: center;
	width: 100%;
}

.points
{
	display: block;
	margin-top: -50px;
	color: white;
	text-align: center;
	font: 12px iran-sans-bold;
}

.TLikes
{
	display: block;
	margin-top: -60px;
	color: white;
	text-align: center;
	font: 12px iran-sans-bold;
}

ul {
    margin: 0; 
    padding:0
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

/* medals List */
.myList
{
    margin-top: 20px;
	list-style: none;
	overflow: auto;
	height: 90px;
    display: flex;
    justify-content: center;
}

/* channels List */
.chList
{
    list-style: none;
	overflow: auto;
    display: flex;
    padding: 30px;
}

.chList li 
{
    position: relative;
}

.baseInfo
{
    /* text-align: right !important; */
    margin-bottom: 20px;
    margin-top: 5px;
}

.chName
{
    position: absolute;
    background-color: #282c34;
    opacity: 70%;
    bottom: 0;
    width: 100%;
}

.chName div
{
    opacity: 100;
    color: white;
}


@media screen and (max-width: 1023px) {
    .myList
    {
        list-style: none;
        overflow: auto;
        height: 90px;
        display: flex;
        justify-content: center;
    }
}

.myList li
{
	text-align: right;
	display: inline;
}

.myList li:first-child {
      right : 0;
      position: relative;
      display: inline;
}

.myList li:nth-child(2) {
      right : -6px;
      position: relative;
      display: inline;
}

.myList li:nth-child(3) {
      right : -12px;
      position: relative;
      display: inline;
}

.myList li:nth-child(4) {
      right : -18px;
      position: relative;
      display: inline;
}

.myList li:nth-child(5) {
      right : -24px;
      position: relative;
      display: inline;
}

.myList li:nth-child(6) {
      right : -30px;
      position: relative;
      display: inline;
}

.myList li:nth-child(7) {
      right : -36px;
      position: relative;
      display: inline;
}

@media screen and (max-width: 560px) {
	.card
	{
		margin-top: -10px;
		width: 100%;
	}
	
	.pimg
	{
		height: 120px;
		width: 120px;
		margin-top: -70px;
	}
	
	.username
	{
		margin-top: 90px;
	}
}

/* NOT FOUND */
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
  background-color: #E4EFF4;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-bg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}

.notfound .notfound-bg > div {
  width: 100%;
  background: #fff;
  border-radius: 90px;
  height: 125px;
}

.notfound .notfound-bg > div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
          box-shadow: 5px 5px 0px 0px #f3f3f3;
}

.notfound .notfound-bg > div:nth-child(2) {
  -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
          box-shadow: 5px 5px 0px 0px #f3f3f3;
  position: relative;
  z-index: 10;
}

.notfound .notfound-bg > div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
          box-shadow: 5px 5px 0px 0px #f3f3f3;
  position: relative;
  z-index: 90;
}

.notfound h1 {
  font-family: iran-sans-bold;
  font-size: 56px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  color: #151515;
}

.notfound h2 {
  font-family: iran-sans-bold;
  font-size: 26px;
  margin: 0;
  font-weight: 700;
  color: #151515;
}

.notfound a {
  font-family: iran-sans-bold;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #A7207E;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}

.notfound-social {
  margin-top: 20px;
}

.notfound-social>a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #fff;
  background-color: #dedede;
  margin: 3px;
  padding: 0px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound-social>a:hover {
  background-color: #18e06f;
}

@media only screen and (max-width: 767px) {

    .mt-1
    {
        width: 50%;
        padding: 10px;
    }

    .notfound .notfound-bg {
      width: 287px;
      margin: auto;
    }

    .notfound .notfound-bg > div {
      height: 85px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound h1 {
    font-size: 68px;
  }

  .notfound h2 {
    font-size: 18px;
  }
}

/* Modal start */
body.modal-open .modal {
    display: flex !important;
    height: 100%;
} 

body.modal-open .modal .modal-dialog {
    margin: auto;
}

.share-row
{
	line-height: 60px;
	padding: 0 0 0 100px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.modal-content
{
	border-radius: 15px;
}
/* Modal end */


/* login page */

.country-name
{
    float: left;
    color: #151515;
}

#LoginOwnerPic
{
    width: 20%;
    height: auto;
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	-o-transform: scale(0.9);
	transform: scale(0.9);
	position: relative;
	top: 5px;
	right: 10px;
	border-radius: 50%;
}

/* login page END */

.postsInProfile
{
    max-height: 100%;
    object-fit: cover;
    padding: 2px;
}

.postsInBlack
{
    background-color: white;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.col{
    display: flex;
    flex-direction: column;
    height: 100px;
}

.card-header-tabs
{
    text-align: center;
}

.wholeLine
{
    text-align: center;
    border-color: #d6619c;
    border-style: solid;
    
    border-right: 0;
    border-left: 0;
    border-width: 0.25px;
    color: #d6619c;
    border-radius: 25px;
}